<template>
  <v-app>
    <AppBar></AppBar>

    <v-main>
      <div class="card-container">
        <v-card>
          <v-card-title>Not an admin?</v-card-title>
          <v-progress-linear color="primary" :value="100"></v-progress-linear>
  
          <v-card-text>
            Overseer is reserved to admins only! Use an admin account to connect and use this website :)
          </v-card-text>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue';

export default {
  name: 'Restricted',
  components: { AppBar },
};
</script>

<style scoped>
.card-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
}

.card-container > * {
  min-width: 30vw;
}
</style>
